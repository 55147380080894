import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
import { STOCK_COINS_STATUS } from '../../../mixins/enum'
const _ = require('lodash')
import vSelect from 'vue-select'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    }
  },
  components: {
    Paginate,
    vSelect
  },
  data() {
    return {
      keyword_lot: "",
      keyword_code: "",
      keyword_color: null,
      keyword_thick: "",
      keyword_brand: null,

      busy: false,
      textInput: '',
      answer: 'ค้นหาจาก รหัสคอยล์ ชื่อคอยล์',
      totalItem: 0,
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      stock_coins_status: STOCK_COINS_STATUS,
      fields: [
        {
          key: "first_received_date",
          label: "วันที่รับม้วน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "lot_no",
          label: "LOT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "Coil No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        { 
          key: 'branch_status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-0.5',
          sortable: true
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-0.5',
          class: 'text-right' 
        }
      ],
      items: []
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: async function (newValue, oldValue) {
      if (newValue) {
        await this.onSearchHandler(this.currentPage)
        await this.loadBrandOptions()
        await this.loadSizeOptions()
        await this.loadColorOptions()
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onCoinListCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.$emit('onCoinListCloseHandler', {
        status: true,
        data: item,
        index: this.index
      })
    },
    async onSearchHandler (currentPage) {
      
      // this.loading = true
      // this.items = []
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      const page = currentPage ? currentPage : 1
      const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/branchstock?page=${page}
        &keyword=${this.textInput}
        &keyword_lot=${this.keyword_lot}
        &keyword_code=${this.keyword_code}
        &keyword_color=${this.keyword_color ? this.keyword_color : ''}
        &keyword_thick=${this.keyword_thick}
        &keyword_brand=${this.keyword_brand ? this.keyword_brand : ""}`)
      setTimeout(() => {
        // this.loading = false
        this.currentData = result.data
        this.items = result.data.data
        this.totalItem = result.data.total
        this.apiStatus = 'ไม่พบข้อมูล.'
        this.busy = false
        if (result) {
          this.items = result.data.data
        } else {
          this.apiStatus = 'ไม่พบข้อมูล'
          this.onExceptionHandler()
        }
      }, 500)
    },
    async loadBrandOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/brand`
        )
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาเลือกยี่ห้อ --" }]
          this.brandOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadSizeOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/size`
        )
        if (result) {
          const starter = [{ uuid: '', name: "-- กรุณาระบุขนาด --" }]
          this.sizeOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadColorOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/color`
        )
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาระบุสี --" }]
          this.colorOptions = starter.concat(result.data)

        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
